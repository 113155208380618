.about {
    width: 100%;
    margin: 0 auto;
    min-height: 300px;
    height: auto;

    h1 {
        color: greenyellow;
        font-size: x-large;
        margin-top: 0px;
    }

    .about-bg {
        position: relative;
        width: 100%;
        height: 50%;

        .about-popup-container {
            width: 78%;
            height: 63%;
            position: absolute; 
            left: 0;
            right: 0; 
            top: 18%;
            margin-left: auto; 
            margin-right: auto;

            .close {
                position: absolute;
                top: 23%;
                right: 4.5%;
                cursor: pointer;
                z-index: 10;

                img {
                    width: 20px;
                    content: url("../assets/close.png");
                }
                img:hover {-webkit-filter: brightness(150%); }
            }

            .about-popup {
                width: 90%;
                height: 65%;
                position: absolute; 
                left: 0; 
                right: 0; 
                top: 20%;
                margin-left: auto; 
                margin-right: auto;
                overflow-x: hidden;
                overflow-y: auto;
                text-align: left;
                background-color: transparent;
                margin-top: 1%;
            }

            /* width */
            ::-webkit-scrollbar {
                width: 0.6vw;
            }
                    
            /* Track */
            ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey; 
                border-radius: 10px;
                margin-top: 30px;
                margin-bottom: 5px;
            }
                        
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #6cff00; 
                border-radius: 10px;
            }

            #about-popup-lore {
                scrollbar-color: green;
            }

            #about-popup-lcc {
                scrollbar-color: blue;
            }

            #about-popup-ourstory {
                scrollbar-color: pink;
            }

            .about-info-header {
                position: absolute;
                top: 0px;
                color: #02f0d8;
                font-family: 'Kdam Thmor Pro', sans-serif;
                font-size: 30px;
                text-align: left;
                font-weight: bolder;
                text-transform: uppercase;
                padding-left: 4%;
                letter-spacing: 1px;
                visibility: hidden;
                animation: showAnimation 0s ease-in 0.5s;
                animation-fill-mode: forwards;
                text-underline-offset: 5px;

                u:after {
                    content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
                }
            }

            .about-info {
                position: absolute;
                top: 35px;
                color: white;
                font-family: 'Kdam Thmor Pro', sans-serif;
                font-size: 18px;
                text-align: left;
                padding-left: 4%;
                padding-right: 4%;
                white-space: pre-wrap;
                visibility: hidden;
                animation: showAnimation 0s ease-in 0.5s;
                animation-fill-mode: forwards;
            }

            @keyframes showAnimation {
                to {
                    visibility: visible;
                }
            }
        }

        #lasercatscolbutton {
            position: absolute;
            left: 11.55%;
            top: 24.1%;
            width: 31.2vw;
        }

        #ourmissionbutton {
            position: absolute;
            left: 61.9%;
            top: 26.8%;
            width: 26.9vw;
        }

        #lorebutton {
            position: absolute;
            left: 36.5%;
            top: 41.8%;
            width: 24.8vw;
        }

        #tavern77button {
            position: absolute;
            left: 13.5%;
            top: 59.9%;
            width: 24vw;
        }

        #ourstorybutton {
            position: absolute;
            left: 64.7%;
            top: 57.3%;
            width: 24.1vw;
        }
    }

    .spritesheet-container {
        visibility: hidden;
    }

    button {
        background:transparent;
        color:transparent;
        border:none;
        cursor: pointer;
    }

    @media (pointer: fine) {
        button:hover {
            .spritesheet-container {
                visibility: visible;
            }
        }
    }

    @media (pointer: coarse) {
        .spritesheet-container {
            visibility: hidden;
        }
    }
}

@media (max-width: 900px) {
    .about {
        width: 100%;

        .about-bg .about-popup {
            .about-info-header {
                font-size: 13px;
            }

            .about-info {
                font-size: 10px;
            }
        }
    }
}

@media not all and (hover: none), (-ms-high-contrast: none) {
    /* behaviour for touch browsers */
}