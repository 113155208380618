
.landing {
    width: 100%;
    text-align: center;
    margin: auto;
  }
  
  .video-container {
    position: relative;
    width: 100%;
  }

  .landingvideo video {
    width: 100%;
    height: auto;
    object-fit:fill;
  }
  
  .landingvideo audio {
    position:absolute;
    top: 90%;
    left:7%;
    width: 15%;
  }

  .enterbutton-container {
    position: absolute;
    left: 82%;
    top: 76.4%;
    width: 16.5vw;
    height: auto;

    @media (pointer: fine) {
      .spritesheet-container {
        visibility: hidden;
      }
      :hover {
        .spritesheet-container {
          visibility: visible;
        }
      }
    }

    @media (pointer: coarse) {
      .spritesheet-container {
        visibility: hidden;
      }
    }
  }

  .skipintro {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-family: 'Kdam Thmor Pro', sans-serif;
    font-weight: bold;
    font-size: 1.1vw;
  }

  .socialmedialink-container {
    position:absolute;
    top:73%;
    left:10.9%;
  }
  
  .socialmedialink {
    display: inline-block;
    margin: 0;
    width: 5vw;
    height: auto;

    img {
      height: 100px;
      width: 200px;
      top: 100%;
    }

    @media (pointer: fine) {
      .spritesheet-container {
        visibility: hidden;
      }
      :hover {
        .spritesheet-container {
          visibility: visible;
        }
      }
    }

    @media (pointer: coarse) {
      .spritesheet-container {
        visibility: hidden;
      }
    }
  }