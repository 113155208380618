.header {
    display: flex;
    justify-content: space-between;
    margin-left: 2vw;
    margin-right: 2vh;
}

.logo {
    content: url('../assets/lclogo.png');
    position: absolute;
    width: 250px;
    top: 1vh;
}

.slider {
    display: flex;

    .ul {
        display: flex;
        margin: 25px;

        li {
            margin: 0 1rem;
            list-style: none;

            a {
                text-decoration: none;
                color: white;
                font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
                font-size: larger;
                font-weight: bolder;
            }

            @media (pointer: fine) {
                a:hover {
                    color: greenyellow;
                }
            }
        }
    }
}

.menu-icon .menu {
    display: none;
}

@media (max-width: 900px) {
    .header {
        min-height: 75px;
    }

    .logo {
        width: 200px;
    }

    .slider {
        display: none;
        background-color: black;

        ul {
            display: none;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .slider.active {
        display: flex;

        ul {
            margin-top: 45px;
            margin-right: 0;
        }

        ul li {
            margin-top: 1vh;
            margin-right: 0;
        }

        ul li a {
            font-size:medium;
            color: white;
        }
    }

    .menu-icon .menu {
        display: block;
        color: white;
        font-size: 50px;
        cursor: pointer;
        position: absolute;
        top: 1vh;
        right: 3vw;
    }

    .closed {
        display: flex;
        cursor: pointer;
        color: white;
        justify-content: center;
        width: 100%;
    }
}