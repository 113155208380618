.roadmap {
  width: 100%;
  margin: 0 auto;

    .roadmap-bg {
      position: relative;
      width: 100%;
      height: auto;
      z-index: 10;
    }

    #roadmap-img {
      width: 100%;
    }

    #planets-img {
      width: 100%;
    }

    #clouds-img {
      width: 100%;
    }

    #stars-img {
      width: 100%;
    }

    #imagestars1 {
      position: absolute;
      width: 100%;
      top: 0%;
      z-index: 15;
    }

    #imagestars2 {
      position: absolute;
      top: 0%;
      z-index: 17;
    }

    #imageplanets {
      position: absolute;
      top: 10%;
      z-index: 20;
    }

    #imageclouds1 {
      position: absolute;
      top: 15%;
      z-index: 21;
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-weight: bolder;
      width: 100%;
      margin: auto;
    }

    #phase1box {
      position: absolute;
      top: 13%;
      width:100%;
      background: url("../assets/rm_phase1box.png");
      background-size: contain;
      z-index: 25;
    }
    #phase2box {
      position: absolute;
      top: 40%;
      width:100%;
      background: url("../assets/rm_phase2box.png");
      background-size: contain;
      z-index: 25;
    }
    #phase3box {
      position: absolute;
      top: 77%;
      width:100%;
      background: url("../assets/rm_phase3box.png");
      background-size: contain;
      z-index: 25;
    }

    #phase1-img {
      width: 100%;
    }

    #phase1content {
      width: 45%;
      float: right;
      margin-right:1%;
    }

    #phase2content {
      width: 40%;
      float: left;
      margin-left:1%;
    }

    #phase3content {
      width: 40%;
      float: right;
      margin-right:1%;
    }

    .phasecontent {
      color: white;
      font-family: 'Kdam Thmor Pro', sans-serif;      
      font-weight: bold;
      font-size: 1.1vw;
      padding: 20px;
    }

    .fade-in {
      transition: opacity 2s;
    }

    #twinklingstars {
    }

    #sky {
      width: 100%;
      margin: auto;
      padding: 0;
    }
}

@media (max-width: 900px) {
  .roadmap {
      width: 100%;
  }
}