.footer-line {
    width: 100%;
    margin-top: 50px;
  }
  
  .footer {
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    margin-top: 20px;
  }
  
  .email-container {
    margin: 0px;
  }
  
  .email-form-container {
    margin: 20px;
  }
  
  .email-label {
    color: white;
    font-size: 20px;
    font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
  }
  
  .email-input-text {
    color: black;
    line-height: 2em;
    font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
  }
  
  .email-submit {
    color: white;
    background-color: green;
    margin:10px;
    padding: 9px;
    font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
  }
  
  @media (pointer: fine) {
    .email-submit:hover {
      background-color: #4CAF50; /* Green */
      color: white;
    }
  }
    
  .email-status {
    color: white;
    font-size: 15px;
    font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
  }

  .email-status-valid {
    color: green;
    font-size: 15px;
    font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
  }

  .email-status-invalid {
    color: red;
    font-size: 15px;
    font-family: 'Kdam Thmor Pro', sans-serif;  justify-content: center;
  }

  .socialmedialink-footer-container {
    max-height: 90px;
  }
  
  .socialmedialink-footer {
    display: inline-block;
    margin: 5px;
    max-width: 33.3%;
    width: 50px;

    img {
      width: 50px;
    }

    @media (pointer: fine) {
      .spritesheet-container {
        visibility: hidden;
      }
      :hover {
        .spritesheet-container {
          visibility: visible;
          position: relative;
          top: -54px;
        }
      }
    }

    @media (pointer: coarse) {
      .spritesheet-container {
        visibility: hidden;
      }
    }
  }
  
  #youtubelink-footer {
    content: url('../assets/img_youtube.png');
  }
  
  #instalink-footer {
    content: url('../assets/img_insta.png');
  }
  
  #discordlink-footer {
    content: url('../assets/img_discord.png');
  }
  
  #twitterlink-footer {
    content: url('../assets/img_twitter.png');
  }
  
  .copyright {
    font-family: 'Kdam Thmor Pro', sans-serif;
    font-size: large;
    color: white;
    margin-top: 0px;
  }